import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import firebaseConfig from './publicClientConfig.js';

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

if (import.meta.env.VITE_USE_FIRESTORE_EMULATOR === 'true') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

export { app, auth };
